/*   
Por Camila Lins
http://camilalins.co
2023
*/

@charset "utf-8";

/* Reset */
*, *:before, *:after {box-sizing:border-box; -moz-box-sizing:border-box;}
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, a, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {margin:0; padding:0; border:0; outline:0; font-size:100%; vertical-align:baseline; background:transparent;}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {display:block;}
audio, canvas, progress, video {display:inline-block;}
img, object, embed, video, iframe {max-width:100%;}
ul {list-style:none;}
blockquote, q {quotes:none;}
blockquote:before, blockquote:after, q:before, q:after {content:''; content:none;}
del {text-decoration:line-through;}
abbr[title], dfn[title] {border-bottom:1px dotted #000; cursor:help;}
table {border-collapse:collapse; border-spacing:0; font-size:inherit; font:100%;}
th {font-weight:bold; vertical-align:bottom;}
td {font-weight:normal; vertical-align:middle;}
hr {display:block; height:1px; border:0; border-top:1px solid #CCC; margin:10px 0; padding:0;}
input, select {vertical-align:middle;}
pre {white-space:pre; white-space:pre-wrap; white-space:pre-line; word-wrap:break-word;}
input[type="radio"] {vertical-align:text-bottom;}
input[type="checkbox"] {vertical-align:bottom; *vertical-align:baseline;}
input:focus, textarea:focus, button:focus {outline:0;}
a:hover, a:active {outline:0;}
small {font-size:85%;}
strong, th {font-weight:bold;}
td, td img {vertical-align:top;}
sub, sup {font-size:75%; line-height:0; position:relative;}
sup {top:-0.5em;}
sub {bottom:-0.25em;}
pre, code, kbd, samp {font-family:monospace, sans-serif;}
label, input[type=button], input[type=submit], button {cursor:pointer;}
body, select, input, textarea {-webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;}
button, input, select, textarea {margin:0; resize:none; color:inherit; font:inherit; border:0; border-radius:0; -webkit-appearance:none; -moz-appearance:none; appearance:none;}
button {width:auto; overflow:visible;}
textarea {overflow:auto;}
ins {background:#FDD900; color:#000; text-decoration:none;}
mark {background:#FDD900; color:#000; font-style:italic; font-weight:bold;}
audio, canvas, iframe, img, svg, video {vertical-align:middle;}
html {font-family:sans-serif; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%;}

::-moz-selection{background:#94c11f; color:#242b5a; text-shadow:none;}
::selection {background:#94c11f; color:#242b5a; text-shadow:none;}

::-webkit-input-placeholder {color:#CCC; opacity:1;}
::-moz-placeholder {color:#CCC; opacity:1;}
::placeholder {color:#CCC; opacity:1;}

/* Toolbox */
.hide {display:none !important;}
.flex {display:flex;}

/* Variáveis */
:root {
    --cor-azul-claro:#f6f7fb;
    --cor-azul:#242b5a;
    --cor-verde:#94c11f;
}

/* Page style */
html {overscroll-behavior:none; overflow-x:hidden;}
body {font:16px 'Inter', sans-serif; color:var(--cor-azul); background:#f6f7fb; background:linear-gradient(195deg, rgba(180,183,200,1) 0%, rgba(246,247,251,1) 45%); min-height:100vh; overflow-x:hidden;}

a {color:var(--cor-verde); text-decoration:none; -webkit-tap-highlight-color:transparent; transition:all 150ms ease-out;}
a:hover {color:#FFF;}

.mobile-check {display:none;}

.video-embed {position:relative; width:100%; padding-bottom:56.3%; height:0;}
.video-embed iframe {position:absolute; top:0; left:0; width:100%; height:100%; z-index:2;}

.wrapper {max-width:1320px; margin:0 auto; padding:0 35px;}

/* Geral */
#wrap {background:url(img/layout/background-logo.png) no-repeat bottom left; background-size:65% auto; padding-bottom:120px;}
.header {max-width:1700px; margin:0 auto; padding:35px 35px 0;}
h1 {width:36%; max-width:575px;}
h1 span {display:block; width:100%; max-width:575px; height:0; padding-bottom:32.52%; background:url(img/layout/logo.svg) no-repeat top left; background-size:100% 100%; text-indent:-99999px;}

.intro {width:50%; margin:160px 0; position:relative; z-index:10;}
.intro h2 {font-size:75px; line-height:85px;}
.intro p {margin:40px 0; font-size:20px;}
.intro h3 a {display:block; padding-left:70px; font-size:58px; color:var(--cor-verde); background:url(img/layout/icon-whatsapp.svg) no-repeat left center; background-size:auto 75%;}
.intro h3 a:hover {transform:scale(1.05);}
.intro h4 {font-size:31px; margin-top:40px;}

.info {justify-content:space-between; position:relative; z-index:10;}
.info h5 {font-size:18px; font-weight:400; text-transform:uppercase; letter-spacing:5px; margin-bottom:30px;}
.about {width:45%;}
.about p {font-size:18px; font-weight:500; line-height:32px; text-align:justify;}
.about p:nth-of-type(2) {margin:20px 0;}
.services {width:45%;}
.services ul {display:grid; grid-template-columns:1fr 1fr; grid-template-rows:1fr 1fr; gap:0px 0px; grid-template-areas:"one two" "three four";}
.services .one {grid-area:one; margin-bottom:50px;}
.services .two {grid-area:two; margin-bottom:50px;}
.services .three {grid-area:three;}
.services .four {grid-area:four;}
.services li {display:flex; flex-direction:column; align-items:center; justify-content:center;}
.services p {font-size:19px; font-weight:500; margin-top:20px;}

#cacamba {position:absolute; top:20px; right:0; width:50%; width:100%; height:0; padding-bottom:40%; background:url(img/content/cacamba-temp.png) no-repeat top right; background-size:auto 100%;}

.webgl {position:absolute; top:0; left:0; width:100vw; z-index:-1;}

/* Footer */
footer {background:var(--cor-azul); padding:18px 0; color:var(--cor-azul-claro);}
footer .wrapper {display:flex; justify-content:space-between; align-items:center;}
footer .address {display:flex; justify-content:space-between; align-items:center;}
footer .address p {display:inline-block; margin-left:20px;}
footer p {font-size:16px;}
footer p.copy {font-size:12px;}
footer .dev {font-size:9px; opacity:.5;}
footer .dev a {color:var(--cor-azul-claro); font-weight:700;}
footer .dev a:hover {text-decoration:underline;}


/* Tapume */
body.tapume #wrap {background:none; display:flex; align-items:center; justify-content:center; flex-direction:column; height:100vh; padding:0 20px; text-align:center;}
.tapume h1 {width:40%; max-width:575px;}
.tapume h1 span {display:block; width:100%; max-width:575px; height:0; padding-bottom:83.42%; background:url(img/layout/logo-vertical.svg) no-repeat top left; background-size:100% 100%; text-indent:-99999px;}
.tapume h2 {font-size:35px; margin:35px 0;}
.tapume p {margin-bottom:20px;}
.tapume h3 a {display:block; padding-left:70px; font-size:58px; color:var(--cor-verde); background:url(img/layout/icon-whatsapp.svg) no-repeat left center; background-size:auto 75%;}
.tapume h3 a:hover {transform:scale(1.05);}

@media screen and (max-width:700px){
    .tapume h1 {width:60%;}
    .tapume h3 a {font-size:9.3vw; line-height:10vw; padding-left:9.5vw;}
}

/* Media Queries*/
@media screen and (max-width:1920px){
    #cacamba {padding-bottom:48%;}
}
@media screen and (max-width:1700px){
    #cacamba {padding-bottom:55%;}
}
@media screen and (max-width:1300px){
    .intro h2 {font-size:5.85vw; line-height:6.6vw;}
    .intro h3 a {font-size:4.6vw; line-height:4.5vw; padding-left:4.8vw;}
    .intro h4 {font-size:2.43vw; line-height:2.43vw;}
}
@media screen and (max-width:1080px){
   .info {display:block;}
   .about {width:100%; margin-bottom:10vw;}
   .services {width:100%;}
}
@media screen and (max-width:900px){
    .intro {width:75%; margin:20vw 0;}
    .intro h2 {font-size:7.85vw; line-height:8.6vw;}
    .intro h3 a {font-size:6.6vw; line-height:6.5vw; padding-left:6.8vw;}
    .intro h4 {font-size:3.5vw; line-height:3.5vw;}

    #cacamba {opacity:.3; padding-bottom:85%;}

    footer .wrapper, footer .address {flex-direction:column; text-align:center;}
    footer .address p {margin:15px 0 0;}
    footer p.copy {margin:15px 0;}
}
@media screen and (max-width:800px){
    h1 {width:45%;}
}
@media screen and (max-width:600px){
    .header {padding:25px 25px 0;}
    .wrapper {padding:0 25px;}
    .intro p {font-size:3.6vw;}
    .about {margin-bottom:15vw;}
    .about p {font-size:3.8vw; line-height:6.2vw;}
    .services ul {display:block;}
    #wrap .services li {margin-bottom:12vw;}
    #wrap .services li:last-child {margin-bottom:0;}
    .services li img {width:55%;}
}

@media screen and (max-width:500px){
    h1 {width:55%;}
    .intro {width:100%; margin:18vw 0;}
    .intro h2 {font-size:11vw; line-height:11.75vw;}
    .intro p {font-size:4.3vw;}
    .intro h3 a {font-size:9.3vw; line-height:10vw; padding-left:9.5vw; text-shadow:0 0 6px #d8dae4;}
    .intro h4 {font-size:4.9vw; line-height:5.1vw;}

    .about p {font-size:4.5vw; line-height:7vw;}
    .services li img {width:60%;}

    #cacamba {opacity:.2; padding-bottom:104%;}
}